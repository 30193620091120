import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { calculatePoints, parseEquation } from '../utils/equationUtils';
import { ZoomInIcon, ZoomOutIcon, ResetZoomIcon } from './icons/ZoomIcons';
import styles from './GraphPlotter.module.css';

Chart.register(zoomPlugin);

/**
 * GraphPlotter component for rendering mathematical equations using Chart.js
 * @param {Object} props - Component props
 * @param {Array<{equation: string, color: string}>} props.equations - Array of equations to plot
 * @param {string} props.selectedEquation - Currently selected equation
 * @param {Function} props.onPointClick - Callback when a point is clicked
 */
// PUBLIC_INTERFACE
const GraphPlotter = ({ equations, selectedEquation, onPointClick }) => {
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(window.innerWidth > 768);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [panelPosition, setPanelPosition] = useState(0);

  const minSwipeDistance = 50;

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const currentTouch = e.touches[0].clientX;
    const diff = touchStart - currentTouch;
    
    // Constrain panel position between -100% (hidden) and 0 (fully visible)
    const newPosition = Math.max(-100, Math.min(0, -diff / 5.96));
    setPanelPosition(newPosition);
  };

  const handleTouchEnd = (e) => {
    setIsDragging(false);
    const end = e.changedTouches[0].clientX;
    setTouchEnd(end);
    
    const diff = touchStart - end;
    
    // If swipe distance is greater than minimum, toggle panel
    if (Math.abs(diff) > minSwipeDistance) {
      setIsPanelVisible(diff < 0);
      setPanelPosition(0);
    } else {
      // Reset panel position if swipe was not long enough
      setPanelPosition(0);
    }
  };

  // Configure chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        position: 'center',
        grid: {
          color: 'var(--color-grid-lines)',
          borderColor: 'var(--color-grid-lines)',
          drawBorder: true,
          lineWidth: 1
        },
        ticks: {
          font: {
            family: 'Space Grotesk',
            weight: 400,
            size: 12
          },
          color: 'var(--color-text-primary)'
        }
      },
      y: {
        type: 'linear',
        position: 'center',
        grid: {
          color: 'var(--color-grid-lines)',
          borderColor: 'var(--color-grid-lines)',
          drawBorder: true,
          lineWidth: 1
        },
        ticks: {
          font: {
            family: 'Space Grotesk',
            weight: 400,
            size: 12
          },
          color: 'var(--color-text-primary)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: (context) => {
            return `(${context.parsed.x.toFixed(2)}, ${context.parsed.y.toFixed(2)})`;
          }
        }
      },
      zoom: {
        limits: {
          x: {min: -10000, max: 10000, minRange: 1},
          y: {min: -10000, max: 10000, minRange: 1}
        },
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: null,
          speed: 8,
          threshold: 8,
          onPan: function() {
            // Update cursor style during panning
            document.body.style.cursor = 'grabbing';
          },
          onPanComplete: function() {
            // Reset cursor style after panning
            document.body.style.cursor = 'default';
          }
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: null,
            speed: 0.15
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
          drag: {
            enabled: true,
            backgroundColor: 'rgba(89, 90, 255, 0.1)',
            borderColor: 'rgba(89, 90, 255, 0.4)',
            borderWidth: 1,
            threshold: 5
          },
          onZoomStart: function() {
            // Update cursor style during zoom
            document.body.style.cursor = 'zoom-in';
          },
          onZoomComplete: function(ctx) {
            // Reset cursor style after zoom
            document.body.style.cursor = 'default';
            console.log('Zoom completed', ctx);
          }
        },
        animations: {
          pan: {
            duration: 250,
            easing: 'easeOutCubic'
          },
          zoom: {
            duration: 250,
            easing: 'easeOutCubic'
          }
        }
      }
    },
    onClick: (event, elements) => {
      if (elements && elements.length > 0) {
        const datasetIndex = elements[0].datasetIndex;
        const equation = equations[datasetIndex].equation;
        onPointClick(equation);
      }
    }
  };

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstance) {
      chartInstance.destroy();
    }

    // Create new chart instance
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      const newChartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: []
        },
        options: chartOptions
      });
      setChartInstance(newChartInstance);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []); // Empty dependency array to create chart only once

  useEffect(() => {
    if (!chartInstance) return;

    // Update chart data
    chartInstance.data.datasets = equations.map(({ equation, color }) => {
      try {
        const equationFn = parseEquation(equation);
        const points = calculatePoints(equationFn);
        
        return {
          label: equation,
          data: points,
          borderColor: color,
          backgroundColor: color,
          borderWidth: equation === selectedEquation ? 3 : 2,
          pointRadius: equation === selectedEquation ? 3 : 0,
          tension: 0.4,
          fill: false
        };
      } catch (error) {
        console.error(`Error plotting equation: ${equation}`, error);
        return null;
      }
    }).filter(Boolean); // Remove null datasets

    chartInstance.update();
  }, [equations, selectedEquation, chartInstance]);

  return (
    <div className={styles.container}>
      <div className={styles.graphArea}>
        <div className={styles.grid}>
          {/* Grid lines are generated dynamically */}
        </div>
        <div className={styles.axisX}></div>
        <div className={styles.axisY}></div>
        <canvas ref={canvasRef} />
      </div>
      <div 
        className={`${styles.equationPanel} ${isPanelVisible ? styles.visible : ''}`}
        style={{ transform: `translateX(${panelPosition}%)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {equations.map(({ equation, color }, index) => (
          <div key={index} className={styles.equation}>
            <div 
              className={index === 0 ? styles.equationIndicatorPrimary : styles.equationIndicatorSecondary}
              style={{ backgroundColor: color }}
            />
            <span>{equation}</span>
          </div>
        ))}
      </div>
      <button className={styles.removeButton}>
        <div className={styles.buttonIcon}>-</div>
      </button>
      <button 
        className={styles.menuButton}
        onClick={() => setIsPanelVisible(!isPanelVisible)}
        aria-label="Toggle equation panel"
      >
        <div className={styles.buttonIcon}>\u2630</div>
      </button>
      <button 
        className={styles.zoomInButton}
        onClick={() => chartInstance?.zoom(1.2)}
        aria-label="Zoom in"
        title="Zoom in"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            chartInstance?.zoom(1.2);
          }
        }}
      >
        <ZoomInIcon />
      </button>
      <button 
        className={styles.zoomOutButton}
        onClick={() => chartInstance?.zoom(0.8)}
        aria-label="Zoom out"
        title="Zoom out"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            chartInstance?.zoom(0.8);
          }
        }}
      >
        <ZoomOutIcon />
      </button>
      <button 
        className={styles.resetButton}
        onClick={() => chartInstance?.resetZoom()}
        aria-label="Reset zoom"
        title="Reset zoom and pan"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            chartInstance?.resetZoom();
          }
        }}
      >
        <ResetZoomIcon />
      </button>
    </div>
  );
};

export default GraphPlotter;

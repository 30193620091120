import React, { useState, useCallback } from 'react';
import './styles/App.css';
import UserInputHandler from './components/UserInputHandler';
import GraphPlotter from './components/GraphPlotter';
import Equation from './components/Equation';

// Available colors for equations
const EQUATION_COLORS = ['#5968FF', '#FF0B69', '#00C6AE', '#F5A623', '#7B61FF', '#FF6B6B'];

// PUBLIC_INTERFACE
function App() {
  const [equations, setEquations] = useState([]);
  const [selectedEquation, setSelectedEquation] = useState(null);

  // Handler for adding new equations
  const handleEquationSubmit = useCallback((equationStr) => {
    setEquations(prevEquations => {
      const colorIndex = prevEquations.length % EQUATION_COLORS.length;
      return [...prevEquations, {
        equation: equationStr,
        color: EQUATION_COLORS[colorIndex]
      }];
    });
  }, []);

  // Handler for removing equations
  const handleEquationRemove = useCallback((indexToRemove) => {
    setEquations(prevEquations => 
      prevEquations.filter((_, index) => index !== indexToRemove)
    );
    // Clear selection if removed equation was selected
    if (equations[indexToRemove]?.equation === selectedEquation) {
      setSelectedEquation(null);
    }
  }, [equations, selectedEquation]);

  // Handler for selecting equations
  const handleEquationSelect = useCallback((equation) => {
    setSelectedEquation(prev => prev === equation ? null : equation);
  }, []);

  return (
    <div className="app">
      <header className="app-header">
        <h1>Graph Plotter</h1>
      </header>
      <main className="app-main">
        <div className="control-panel">
          <div className="equations-list">
            {equations.map((eq, index) => (
              <Equation
                key={`${eq.equation}-${index}`}
                equation={eq.equation}
                color={eq.color}
                isSelected={eq.equation === selectedEquation}
                onClick={() => handleEquationSelect(eq.equation)}
                onRemove={() => handleEquationRemove(index)}
              />
            ))}
          </div>
        </div>
        <div className="graph-area">
          <UserInputHandler onSubmit={handleEquationSubmit} />
          <GraphPlotter
            equations={equations}
            selectedEquation={selectedEquation}
            onPointClick={handleEquationSelect}
          />
        </div>
      </main>
    </div>
  );
}

export default App;

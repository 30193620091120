import React, { useState } from 'react';
import { validateEquation } from '../utils/equationUtils';

/**
 * UserInputHandler component for handling equation input and validation
 * @param {Object} props - Component props
 * @param {Function} props.onSubmit - Callback function when valid equation is submitted
 */
// PUBLIC_INTERFACE
const UserInputHandler = ({ onSubmit }) => {
  const [equation, setEquation] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEquation(value);
    // Clear error when user starts typing
    if (error) setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate equation
    if (!validateEquation(equation)) {
      setError('Invalid equation format. Please use the format y = f(x)');
      return;
    }

    // Submit valid equation
    onSubmit(equation);
    setEquation(''); // Clear input after successful submission
  };

  return (
    <div className="user-input-handler">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div className="input-with-icon">
            <input
              type="text"
              value={equation}
              onChange={handleInputChange}
              placeholder="Enter equation (e.g., y = x^2)"
              className={`equation-input ${error ? 'error' : ''}`}
            />
            <button type="submit" className="submit-button">
              <span className="plus-icon">+</span>
            </button>
          </div>
        </div>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default UserInputHandler;

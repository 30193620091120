// Equation utility functions for graph plotter

/**
 * Validates if a string is a valid mathematical equation
 * @param {string} equation - The equation string to validate
 * @returns {boolean} True if valid, false otherwise
 */
// PUBLIC_INTERFACE
export const validateEquation = (equation) => {
  if (!equation || typeof equation !== 'string') {
    return false;
  }

  // Basic format check: must contain 'y =' or 'y='
  if (!/y\s*=/.test(equation)) {
    return false;
  }

  try {
    // Remove 'y=' part and validate the expression
    const expression = equation.split('=')[1].trim();
    
    // Check for invalid characters
    if (/[^x\s\d+\-*/()^.\w]/.test(expression)) {
      return false;
    }

    // Check for balanced parentheses
    const stack = [];
    for (const char of expression) {
      if (char === '(') {
        stack.push(char);
      } else if (char === ')') {
        if (stack.length === 0) return false;
        stack.pop();
      }
    }
    if (stack.length > 0) return false;

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Parses a mathematical expression and returns a function that can evaluate it
 * @param {string} equation - The equation string to parse
 * @returns {Function} A function that takes x as input and returns y
 * @throws {Error} If the equation is invalid or cannot be parsed
 */
// PUBLIC_INTERFACE
export const parseEquation = (equation) => {
  if (!validateEquation(equation)) {
    throw new Error('Invalid equation format');
  }

  const expression = equation.split('=')[1].trim();
  
  // Create a function that evaluates the expression
  try {
    // Replace mathematical functions with Math equivalents
    const processedExpr = expression
      .replace(/sin/g, 'Math.sin')
      .replace(/cos/g, 'Math.cos')
      .replace(/tan/g, 'Math.tan')
      .replace(/sqrt/g, 'Math.sqrt')
      .replace(/\^/g, '**');

    // Create function that evaluates the expression
    return new Function('x', `return ${processedExpr}`);
  } catch (error) {
    throw new Error('Failed to parse equation: ' + error.message);
  }
};

/**
 * Calculates points for plotting a given equation
 * @param {Function} equationFn - The parsed equation function
 * @param {number} start - Start x value
 * @param {number} end - End x value
 * @param {number} steps - Number of points to calculate
 * @returns {Array<{x: number, y: number}>} Array of points
 */
// PUBLIC_INTERFACE
export const calculatePoints = (equationFn, start = -10, end = 10, steps = 200) => {
  const points = [];
  const step = (end - start) / steps;

  for (let i = 0; i <= steps; i++) {
    const x = start + (i * step);
    try {
      const y = equationFn(x);
      if (isFinite(y) && !isNaN(y)) {
        points.push({ x, y });
      }
    } catch (error) {
      // Skip points that cause evaluation errors
      continue;
    }
  }

  return points;
};

/**
 * Formats an equation string for display
 * @param {string} equation - The equation to format
 * @returns {string} Formatted equation
 */
// PUBLIC_INTERFACE
export const formatEquation = (equation) => {
  if (!equation) return '';
  
  return equation
    .replace(/\*/g, '×')
    .replace(/\//g, '÷')
    .replace(/\^/g, '^')
    .trim();
};
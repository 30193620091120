import React, { useState, useEffect } from 'react';
import { formatEquation, validateEquation } from '../utils/equationUtils';

/**
 * Equation component for displaying and editing equations with color indicators
 * @param {Object} props - Component props
 * @param {string} props.equation - The equation string to display/edit
 * @param {string} props.color - The color associated with the equation
 * @param {Function} props.onRemove - Callback function when remove button is clicked
 * @param {Function} props.onClick - Callback function when equation is clicked
 * @param {Function} props.onAdd - Callback function when add button is clicked
 * @param {Function} props.onChange - Callback function when equation is changed
 * @param {boolean} props.isSelected - Whether the equation is currently selected
 */
// PUBLIC_INTERFACE
const Equation = ({ equation, color, onRemove, onClick, onAdd, onChange, isSelected }) => {
  const [inputValue, setInputValue] = useState(equation);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setInputValue(equation);
  }, [equation]);
  const handleRemove = (e) => {
    e.stopPropagation(); // Prevent triggering onClick when removing
    onRemove();
  };

  const handleAdd = (e) => {
    e.stopPropagation(); // Prevent triggering onClick when adding
    onAdd();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    
    // Clear error when user starts typing
    if (error) setError('');
    
    // Validate equation as user types
    if (!value.trim()) {
      setError('Equation cannot be empty');
    } else if (!validateEquation(value)) {
      setError('Invalid equation format. Please use y = f(x)');
    } else {
      onChange(value);
    }
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    setError(''); // Clear error when input loses focus
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
    }
  };

  return (
    <div 
      className={`equation ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="equation-content">
        <div 
          className="color-indicator"
          style={{ backgroundColor: color }}
        />
        {isEditing ? (
          <div className="input-container">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className={`equation-input ${error ? 'error' : ''}`}
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        ) : (
          <span 
            className="equation-text"
            onDoubleClick={handleDoubleClick}
          >
            {formatEquation(inputValue)}
          </span>
        )}
      </div>
      <button 
        className="remove-button"
        onClick={handleRemove}
        aria-label="Remove equation"
      >
        ×
      </button>
      <button 
        className="add-button"
        onClick={handleAdd}
        aria-label="Add equation"
      >
        +
      </button>
    </div>
  );
};

export default Equation;
